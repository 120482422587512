import React from "react";
import "src/scss/components/_quoteTestimonial.scss";
import { AccruentSlider,StarCount,AccruentImg } from "../commonComponents";

/**
 * @ALL Types - Array of Objects - [{ `image`: Reference to image imported, `name`: String, `position`: String, `quote`: String, `stars`: Number}]
 * @type "c" - has isEvidence[optional] key to make the card blue
 */
const QuoteTestimonial = ({
  type,
  contentA,
  contentB,
  contentC,
  contentD,
  naturalSlideHeight = 1,
  nid,
}) => {
  if (type === "a") {
    // Takes an array and create several arrays inside it, with `size` items each.
    const chunk = (arr, size) =>
      arr.reduce(
        (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
        []
      );
    const dividedContentA = chunk(contentA, 4);
    return (
      <div
        className="solutions-testimonial-wrapper"
        component="QuoteTestimonial"
        type={type}
        nid={nid}
      >
        <h2 className="title title-3 mt-3 has-text-centered">
          REVIEWS
        </h2>
        <AccruentSlider>
          {dividedContentA &&
            dividedContentA.map((innerArray, index) => {
              return (
                <div className="columns is-multiline" key={index}>
                  {innerArray.map((testimonial, index) => (
                    <div
                      className="column is-two-fifths testimonial-a-card"
                      key={index + testimonial}
                    >
                      {testimonial.image ? (
                        // If the user image exist display the image
                        // if no image exist then display icon
                        <img
                          src={testimonial.image}
                          className="image is-32x32"
                          alt=""
                          decoding = "async"
                        />
                      ) : (
                        <i
                          className="fa fa-user-circle fa-3x has-text-gray"
                          aria-hidden="true"
                        ></i>
                      )}
                      <div className="mb-3">"{testimonial.quote}"</div>
                      <StarCount count={testimonial.stars} />
                      <div className="has-text-weight-semibold is-size-7">
                        {testimonial.name}
                      </div>
                      <div className="is-size-7 mb-3">
                        {testimonial.position}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
        </AccruentSlider>
      </div>
    );
  }

  if (type === "b") {
    if (contentB && contentB.length === 1) {
      return contentB.map((item, i) => (
        <div
          className="columns is-vcentered quote-testimonial"
          key={item.name}
          component="QuoteTestimonial"
          type={type}
          nid={item.nid}
        >
          {item.image && (
            <div className="column is-3 is-offset-2">
              {item.image ? (
                // If the user image exist display the image
                // if no image exist then display icon
                <figure className="image is-128x128">
                  <AccruentImg
                    file={item.image}
                    imgStyle={{
                      "borderRadius": "9999px",
                    }}
                  />
                </figure>
              ) : (
                <figure className="image is-128x128">
                  <i
                    className="fa fa-user-circle fa-7x has-text-gray"
                    aria-hidden="true"
                  ></i>
                </figure>
              )}
               <h6 className="title title-6 mt-2 is-uppercase">
                {item.name}
              </h6>
              <h6 className="title title-6 is-uppercase">{item.position}</h6>
            </div>
          )}
          <div className={item.image ? "column is-5" : "column is-12"}>
            {!item.image && (
              <div className="quote-symbol tw-text-accruent_kingfisher_daisy">
                <i className="fa fa-quote-left fa-3x" aria-hidden="true"></i>
              </div>
            )}
            <div className="is-size-4 is-size-6-mobile is-size-5-tablet mt-2">
              <span className="quote-text tw-text-heading_color tw-text-[normal_normal_medium_22px/29px_Roboto]">
                {item.quote}
              </span>
            </div>
            {contentB.stars && <StarCount size="30px" count={item.stars} />}
            {!item.image && (
              <div>
                <h6 className="title title-6 mt-1 mb-0">
                  {item.name}
                  {item.position ? ',' : ''}
                </h6>
                <h6 className="title title-6">
                  {item.position}
                </h6>
              </div>
            )}
          </div>
        </div>
      ));
    } else
      return (
        <div className="solutions-testimonial-wrapper min-h-0 testimonial-carousel-b">
          <AccruentSlider
            settings={{
              adaptiveHeight: true,
            }}
          >
            {contentB &&
              contentB.map((item, i) => (
                <div
                  className="columns is-vcentered"
                  component="QuoteTestimonial"
                  nid={item.nid}
                >
                  <div className="column is-3 is-offset-2">
                    {item.image ? (
                      // If the user image exist display the image
                      // if no image exist then display icon
                      <figure className="image is-128x128">
                        <AccruentImg
                          file={item.image}
                          imgStyle={{
                            "borderRadius": "9999px",
                          }}
                        />
                      </figure>
                    ) : (
                      <figure className="image is-128x128">
                        <i
                          className="fa fa-user-circle fa-7x has-text-gray"
                          aria-hidden="true"
                        ></i>
                      </figure>
                    )}
                    <h6 className="title title-6 mt-2 is-uppercase">
                      {item.name}
                    </h6>
                    <h6 className="title title-6 is-uppercase">{item.position}</h6>
                  </div>
                  <div className="column is-6">
                    <div className="is-size-4 is-size-6-mobile is-size-5-tablet">
                      {item.quote}
                    </div>
                    {contentB.stars && (
                      <StarCount size="30px" count={item.stars} />
                    )}
                  </div>
                </div>
              ))}
          </AccruentSlider>
        </div>
      );
  }

  if (type === "c") {
    return (
      <div
        className="solutions-testimonial-wrapper"
        component="QuoteTestimonial"
        type={type}
        nid={nid}
      >
        <div className="is-size-2 has-text-weight-semibold has-text-centered mb-3">
          REVIEWS
        </div>
        <AccruentSlider>
          {contentC &&
            contentC.map((card, i) => {
              const isEvidence = card.isEvidence ? "is-evidence" : "";
              return (
                <div className={`box testimonal-c-card ${isEvidence}`} key={i}>
                  <div className="has-text-centered">
                    {card.image ? (
                      // If the user image exist display the image
                      // if no image exist then display icon
                      <img src={card.image} className="image is-32x32" alt="img" decoding = "async"/>
                    ) : (
                      <i
                        className="fa fa-user-circle fa-3x has-text-gray"
                        aria-hidden="true"
                      ></i>
                    )}
                    <div className="has-text-weight-semibold is-size-7">
                      {card.name}
                    </div>
                    <div className="is-size-7 mb-3">{card.position}</div>
                    <div className="mb-3">"{card.quote}"</div>
                    <StarCount count={card.stars} isWhite={card.isEvidence} />
                  </div>
                </div>
              );
            })}
        </AccruentSlider>
      </div>
    );
  }

  if (type === "d") {
    return (
      <div
        className="solutions-testimonial-wrapper"
        component="QuoteTestimonial"
        type={type}
        nid={nid}
      >
        <div className="columns is-6">
          {contentD &&
            contentD.map((item, index) => (
              <div
                className="column is-5 testimonial-a-card"
                key={index + item.name}
              >
                {item.image ? (
                  // If the user image exist display the image
                  // if no image exist then display icon
                  <img src={item.image} className="image is-32x32" alt="img" decoding = "async"/>
                ) : (
                  <i
                    className="fa fa-user-circle fa-3x has-text-gray"
                    aria-hidden="true"
                  ></i>
                )}
                <div className="mb-3">"{item.quote}"</div>
                <StarCount count={item.stars} />
                <div className="has-text-weight-semibold is-size-7">
                  {item.name}
                </div>
                <div className="is-size-7 mb-3">{item.position}</div>
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default QuoteTestimonial;
